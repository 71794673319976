import { Button } from '@mui/material'
import React from 'react'
import {useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'


export default function Home() {

    const dispatch = useDispatch()

    return (
        <div className='temporaryBox'>
{/* Running app in: {process.env.NODE_ENV} <br /> 
            <Button variant="contained">Make Request</Button>
            <Link to="/random">Events Page</Link>
            TEST! */}

            <h1>Imvyt Cloud</h1>
            <h2>Productivity + Cloud Solutions <br /> for Event Teams</h2>

            <h3>New Portal Launching Soon</h3>
        </div>
    )
}
