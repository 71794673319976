import { Avatar, Button, ButtonGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import { get } from "lodash";

export default function Attending(props) {
  let eventDetails = props.eventDetails;
  let showAttendeeCount = get(eventDetails, "showAttendeeCount", false);
  let showAttendeeNames = get(eventDetails, "showAttendeeNames", false);

  const [tab, setTab] = useState(showAttendeeNames ? "guests" : "none");

  return (
    <div className="Attending_container">

      {showAttendeeCount && (
      <ButtonGroup fullWidth size="large" color="secondary" disableElevation>
        <Button
          fullWidth
          variant={tab === "guests" ? "contained" : "outlined"}
          onClick={showAttendeeNames ? () => setTab("guests") : null}
        >
          <div>
            <Typography variant="h4">73</Typography>
            <Typography>GOING</Typography>
          </div>
        </Button>
        <Button
          fullWidth
          variant={tab === "maybe" ? "contained" : "outlined"}
          onClick={showAttendeeNames ? () => setTab("maybe") : null}
        >
          <div>
            <Typography variant="h4">1</Typography>
            <Typography>MAYBE</Typography>
          </div>
        </Button>
        <Button
          fullWidth
          variant={tab === "no" ? "contained" : "outlined"}
          onClick={showAttendeeNames ? () => setTab("no") : null}
          //   disabled={true}
        >
          <div>
            <Typography variant="h4">0</Typography>
            <Typography>NO</Typography>
          </div>
        </Button>
      </ButtonGroup>
      )}

      {showAttendeeCount && showAttendeeNames && (
        <div className="Avatar_grid_container">
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Tom B.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Ciara F.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">James G.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Matthew B.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Ben G.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Patricia B.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Peter B.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Erica O.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Tina B.</Typography>
          </div>
          <div className="Avatar_grid_item">
            <Avatar sx={{ width: 40, height: 40 }} />
            <Typography variant="">Mike B.</Typography>
          </div>
        </div>
      )}
    </div>
  );
}
