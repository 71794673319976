import axios from "axios";
import { getAuth, signOut } from "firebase/auth";

import firebase from "../../util/firebase";
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED } from "../types";

// AUTHENTICATION FUNCTIONS
const auth = getAuth(firebase);

async function getToken() {
  let newtoken;
  try {
    newtoken = await auth.currentUser.getIdToken(true);
    setAuthorizationHeader(newtoken); // Helper Function Defined Below
  } catch (err) {
    throw new Error ("Can't refresh token - likely the user isn't logged in. Users shouldn't be able to trigger this as we shouldn't be make auth requests without being logged in.");
  }
}

export const exportedGetToken = () => getToken();

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = FBIdToken;
  localStorage.setItem("FBIdToken", FBIdToken);
};

export const tokenRefresh = () => (dispatch) => {
  dispatch({ type: "SET_LOADING" });
  getToken()
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUserData = () => (dispatch) => {
  axios
  .get("/user")
  .then((res) => {
    dispatch({ type: SET_AUTHENTICATED, payload: res.data });
    dispatch({ type: "STOP_LOADING" });
  })
  .catch((err) => {
    if (err.response) {
      console.log(err);
    }
    console.log(err.response);
  });
};

export const logOut = () => (dispatch) => {

  signOut(auth)
    .then(() => {
      dispatch({ type: SET_UNAUTHENTICATED });
      localStorage.removeItem("FBIdToken"); //TODO are we ok with this? What if someone doesn't log out but the local storage is still there?
      axios.defaults.headers.common["Authorization"] = "";
      console.log("signed out");
    })
    .catch((error) => {
      // An error happened.
    });
};