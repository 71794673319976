import { Button, Typography } from "@mui/material";
import React from "react";
import { Keyhole, MapPin, Watch, CalendarBlank, FaceMask } from "phosphor-react";
import {get} from 'lodash';
import dayjs from 'dayjs'

export default function EventDetailBox(props) {

  let eventDetails = props.eventDetails

  let startDateTime = dayjs(get(eventDetails, 'startDateTime')).format('h:mmA dddd')
  let endDateTime = dayjs(get(eventDetails, 'endDateTime')).format('MMMM D, YYYY')
  let lengthNumber = dayjs(get(eventDetails, 'endDateTime')).diff(get(eventDetails, 'startDateTime'))/3600000
  let length = lengthNumber > 1 ? `${lengthNumber} Hr` : `${lengthNumber} Mins`
  let locationName = get(eventDetails, 'locationName')
  let locationAddress = get(eventDetails, 'locationAddress')
  let vaccine = get(eventDetails, 'vaccine', false)

  return (
    <div className="DateTime_container">
      <div className="DateTime_row">
        <CalendarBlank size={32} color="grey" weight="light" className="DateTime_icon"  />
        <div className="DateTime_column">
        <Typography variant="listTitle">{startDateTime}</Typography>
        <Typography variant="listSubtitle">{endDateTime}</Typography>
        </div>
      </div>

      <div className="DateTime_row">
        <Watch size={32} color="grey" weight="light" className="DateTime_icon"  />
        <Typography variant="listTitle">{length}</Typography>
      </div>

      <div className="DateTime_row">
        <MapPin size={32} color="grey" weight="light" className="DateTime_icon"  />
        <div className="DateTime_column">
            <Typography variant="listTitle">{locationName}</Typography>
            <Typography variant="listSubtitle">{locationAddress}</Typography>
            </div>
      </div>

      <div className="DateTime_row">
        <Keyhole size={32} color="grey" weight="light" className="DateTime_icon" />
        <div className="DateTime_column">
        <Typography variant="listTitle">Private</Typography>
        <Typography variant="listSubtitle">Only people who are invited</Typography>
        </div>
      </div>

      {vaccine && (
      <div className="DateTime_row">
        <FaceMask size={32} color="grey" weight="light" className="DateTime_icon"  />
        <div className="DateTime_column">
        <Typography variant="listTitle">Vaccine Required</Typography>
        <Typography variant="listSubtitle">Against COVID-19</Typography>
        </div>
      </div>
      )}

    </div>
  );
}
