import {
  SET_USER,
  SET_ERRORS,
  LOADING_USER,
  SET_UNAUTHENTICATED,
  SET_FBINIT,
  SET_USERS,
  LOADING_USERS,
  SET_FRIENDS,
  SET_AUTHENTICATED,
} from "../types";

const initialState = {
  authenticated: false,
  minorLoad: false,
  FBInit: false,
  loading: false,
  credentials: {},
  clearForm: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        credentials: {
          ...action.payload
        }
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        credentials: {}
      };
    default:
      return state;
  }
}
