import { Button, Collapse, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FadeIn from "react-fade-in";

export default function RSVPNameAcceptStep() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onAccept = (data) => {
    console.log(data);
    console.log("accept");
  };

  const onMaybe = (data) => {
    console.log(data);
    console.log("maybe");
  };

  const onDecline = (data) => {
    console.log(data);
    console.log("decline");
  };

  let needsName = true;

  return (
    <div className="sign-in-container">
      <div className="sign-in-box">
        <div className="stacked_row">
          <FadeIn>
            <form id="name-form">
              <Collapse in={needsName}>
                <div className="stacked_row">
                  <Typography variant="h5">
                    Let Liz know who you are.
                  </Typography>
                </div>

                <div className="stacked_row">
                  <TextField
                    label="First Name"
                    autoFocus
                    fullWidth
                    {...register("firstName", { required: true })}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName && "required"}
                  />
                </div>

                <div className="stacked_row">
                  <TextField
                    fullWidth
                    label="Last Name"
                    {...register("lastName", { required: true })}
                    error={errors.lastName ? true : false}
                    helperText={errors.lastName && "required"}
                  />
                </div>
              </Collapse>

              <Collapse in={!needsName}>
                <div className="stacked_row">
                  <Typography variant="h5" sx={{ pt: 1 }}>
                    Let Liz know if you can attend
                  </Typography>
                </div>
              </Collapse>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    form="name-form"
                    onClick={handleSubmit(onAccept)}
                    variant="outlined"
                    disableElevation
                    size="large"
                    fullWidth
                  >
                    CONFIRM ATTENDING
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    onClick={handleSubmit(onMaybe)}
                    disableElevation
                    size="large"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                  >
                    MAYBE
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    onClick={handleSubmit(onDecline)}
                    disableElevation
                    size="large"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                  >
                    CAN'T GO
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FadeIn>
        </div>
      </div>
    </div>
  );
}
