import React from "react";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const variants = {
  open: { opacity: 1, rotate: 360 },
  closed: { opacity: 0, rotate: 0 },
};

export default function EmojiStepper() {

  const emoji = useSelector((state) => state.ui.emoji);

  return (
    <>
      <div className="EmojiStepper_container">
        <div className="EmojiStepper_circle"></div>
        <motion.div
          animate={emoji === "wave" ? "open" : "closed"}
          variants={variants}
          className="EmojiStepper_emoji"
        >
          👋
    </motion.div>
        <motion.div
          animate={emoji === "point" ? "open" : "closed"}
          variants={variants}
          className="EmojiStepper_emoji"
        >
          👇
        </motion.div>
        <motion.div
          animate={emoji === "shaka" ? "open" : "closed"}
          variants={variants}
          className="EmojiStepper_emoji"
        >
          🤙
        </motion.div>
      </div>
    </>
  );
}
