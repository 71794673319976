import React, {useState} from "react";
import { useSelector } from "react-redux";
import LoginSetup from "../authentication/LoginSetup";
import { get } from "lodash";
import RSVPStatus from "./RSVPStatus";
import PhoneStep from "../authentication/steps/PhoneStep";
import RSVPNameAcceptStep from "./RSVPNameAcceptStep";
import { Button, Divider } from "@mui/material";

export default function RSVPflow() {
  
  // GET VALUES
  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated"); 
  let credentials = get(user, "credentials");

  // BASE PATH
  let authentication = [];
  if (authenticated === false) {
    authentication.push("PhoneStep")
  }

  // PRE ACCEPT
  let preAccept = []

  // NAME STEP
  let accept = ["RSVPNameAcceptStep"];

  // POST ACCEPT
  let postAccept = []

  // COMBINE INTO FINAL PATH
  const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
  let path = concat(authentication, preAccept, accept, postAccept)

  // Track Active Step
  let totalSteps = path.length
  const [stepCount, setStepCount] = useState(0);
  let activeStep = path[stepCount]

  return (
    <>
      {stepCount + 1} / {totalSteps}
      
      {activeStep}

      <Divider />

      {activeStep === "PhoneStep" && <PhoneStep />}
      {activeStep === "RSVPNameAcceptStep" && <RSVPNameAcceptStep />}

<Divider />

    </>
  );
}
