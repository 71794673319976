import { Grid, Typography } from "@mui/material";
import React from "react";
import RSVPModal from "../components/RSVP/RSVPModal";
import Attending from "../components/ux/event/Attending";
import AvatarLockup from "../components/ux/event/AvatarLockup";
import EventDetailBox from "../components/ux/event/EventDetailBox";
import DateTimeBar from "../components/ux/event/DateTimeBar";
import EventImage from "../components/ux/event/EventImage";
import ShareFavorite from "../components/ux/event/ShareFavorite";
import FadeIn from 'react-fade-in';

const eventDetails = {
  title: "Event title goes here",
  host: "Liz Lemon",
  description: "This NFT Card will give you Access to Special Airdrops. To learn more about UI8 please visit https://ui8.net",
  startDateTime: 1642312800000,
  endDateTime: 1642327200000,
  locationName: 'Optimism Brewery',
  locationAddress: 'Capitol Hill, Seattle',
  privacy: 'private',
  vaccine: true,
  showAttendeeCount: true,
  showAttendeeNames: true,
}

export default function EventOverview() {
  return (
    <div className="maincontent_center">
      <FadeIn>
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12} md={7}>
          <EventImage />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="h3">{eventDetails.title}</Typography>
          <div className="stacked_row space_between">
            <AvatarLockup eventDetails={eventDetails} />
            <ShareFavorite />
          </div>
          <div className="stacked_row">
            <RSVPModal />
          </div>
          <div className="stacked_row">
            <Typography variant="body1">
              {eventDetails.description}
            </Typography>
          </div>
          <div className="stacked_row">
            <EventDetailBox eventDetails={eventDetails} />
          </div>

          <div className="stacked_row">
            <Attending eventDetails={eventDetails}  />
          </div>
        </Grid>
      </Grid>
      </FadeIn>
    </div>
  );
}
