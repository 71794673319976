import React from 'react'
import { useSelector } from 'react-redux';
import { logOut } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import Unsplash from 'react-unsplash-wrapper'
import { Avatar } from '@mui/material';

export default function ProfilePicture() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    return (
        <div className='ProfilePicture_container' onClick={() => dispatch(logOut())}>
            {user.authenticated ? (
                <Unsplash width="30" height="30" keywords="profile" img />
            ) : (
                <Avatar sx={{ width: 30, height: 30 }}  />
            )}
            
        </div>
    )
}
