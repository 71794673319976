import { Avatar, Typography } from "@mui/material";
import React from "react";

export default function AvatarLockup(props) {
  return (
    <div className="AvatarLockup_container">
      <Avatar />
      <div className="AvatarLockup_textalign">
        <Typography variant="itemCategory">Host</Typography>
        <Typography variant="itemName">{props.eventDetails.host}</Typography>
      </div>
    </div>
  );
}
