import React from 'react'
import {Export, Heart} from "phosphor-react";

export default function ShareFavorite() {
    return (
        <div className='ShareFavorite_container'>
            <Export size={25} color="grey" className='ShareFavorite_icon' />
            <Heart size={25} color="grey" className='ShareFavorite_icon' />
        </div>
    )
}
