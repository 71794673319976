import {
  Button,
  Collapse,
  Hidden,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Link, Password, FingerprintSimple } from "phosphor-react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FadeIn from 'react-fade-in';

import { useDispatch } from "react-redux";

export default function EventType() {
  const theme = useTheme();
  const shrink = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch()

  const [explainerVisible, setExplainerVisible] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setExplainerVisible(shrink)
  }, [shrink])

  const setNewEventPrivacy = (privacy) => {
    dispatch({type: "SET_NEW_EVENT_PRIVACY", payload: privacy})
    dispatch({type: "SET_NEW_EVENT_STEP", payload: 2})
    navigate("/create/details")
  }


  return (
    <>
    <FadeIn>
      <div className="center_text">
        <Typography variant="h3">Create an Event</Typography>

        <Typography variant="h5" sx={{ mt: 2 }}>
          It's free! Start by selecting the privacy you want for your event.
        </Typography>

        <Collapse in={explainerVisible}>
          <Typography variant="body1" sx={{ mt: 1 }}>
            You can change this at any time, but it won’t impact people that
            have already accepted your invitation.
          </Typography>
        </Collapse>

        <Hidden smUp>
          <Button disableElevation onClick={() => setExplainerVisible(!explainerVisible)} size="small" sx={{ mt: 1 }} color="secondary">
            {explainerVisible ? 'less' : 'more'} info
          </Button>
        </Hidden>
      </div>

      <div className="center_content">
        <div className="large_list">
          <div className="large_list_item" onClick={() => setNewEventPrivacy('url')}>
            <Link size={32} weight="light" className="large_list_icon" />
            <div>
              <Typography variant="clickableListTitle">Basic URL</Typography>

              <Typography variant="body2">
                Anyone with the link can see details and RSVP
              </Typography>
            </div>
          </div>

          <div className="large_list_item" onClick={() => setNewEventPrivacy('password')}>
            <Password size={32} className="large_list_icon" />
            <div>
              <Typography variant="clickableListTitle">
                Password Protected
              </Typography>

              <Typography variant="body2">
                Users need the event password to see details and RSVP
              </Typography>
            </div>
          </div>

          <div className="large_list_item" onClick={() => setNewEventPrivacy('invite')}>
            <FingerprintSimple size={32} className="large_list_icon" />
            <div>
              <Typography variant="clickableListTitle">Invite Only</Typography>

              <Typography variant="body2">
                Choose who can RSVP and create single use links
              </Typography>
            </div>
          </div>
        </div>
      </div>
      </FadeIn>
    </>
  );
}
