import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { tokenRefresh } from './actions/userActions'
import thunk from 'redux-thunk';
import jwtDecode from 'jwt-decode';

import userReducer from './reducers/userReducer';
import uiReducer from './reducers/uiReducer';

const initialState = {};

const checkToken = store => next => action => {
    const token = localStorage.FBIdToken;
    if(token){
        const decodedToken = jwtDecode(token);
        if(decodedToken.exp * 1000 < Date.now()){
          console.log('token has expired')
          store.dispatch(tokenRefresh())
        }
    }
    return next(action)
  }

const middleware = [
    thunk,
    // checkToken
];

const reducers = combineReducers({
    user: userReducer,
    ui: uiReducer,
});

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
        ? a => a
        : window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;