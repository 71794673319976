import {
  Button,
  ButtonGroup,
  Collapse,
  Drawer,
  Fade,
  Hidden,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import PhoneStep from "../authentication/steps/PhoneStep";
import EmojiStepper from "../authentication/emoji/EmojiStepper";
import LoginSetup from "../authentication/LoginSetup";
import RSVPflow from "./RSVPflow";
import { useDispatch, useSelector } from "react-redux";

export default function RSVPModal() {


  const isOpen = useSelector((state) => state.ui.drawerOpen);

  const dispatch = useDispatch();

  const openDrawer = () => {
    dispatch({ type: "DRAWER_OPEN"});
  }

  const closeDrawer = () => {
    dispatch({ type: "DRAWER_CLOSED"});
  }

  const [modalSnapState, setModalSnapState] = useState(0);

  const modalContent = (
    <div className="modal_content">
      <Hidden smDown>
        <EmojiStepper />
      </Hidden>
      <RSVPflow />
    </div>
  );

  return (
    <>
      {/* <ButtonGroup fullWidth size="large" variant="outlined">
        <Button
          fullWidth
          onClick={() => setOpen(true)}
        >
          ATTENDING
        </Button>
        <Button
          fullWidth
          onClick={() => setOpen(true)}
        >
          MAYBE
        </Button>
        <Button
          fullWidth
          onClick={() => setOpen(true)}
        >
          NO
        </Button>
      </ButtonGroup> */}

      {/* <Button
        size="large"
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => setOpen(true)}
      >
        Respond to Liz's invite
      </Button> */}



      <div className={isOpen ? "RSVP_drawer_backdrop_open" : "RSVP_drawer_backdrop_closed"} onClick={closeDrawer}></div>

      <div className={`RSVP_drawer ${isOpen ? "RSVP_drawer_expanded" : "RSVP_drawer_shrunk"}`}>

        {!isOpen && (
        <Button
          size="large"
          variant="contained"
          disableElevation
          fullWidth
          onClick={openDrawer}
        >
          Respond to Liz's invite
        </Button>
        )}

        
          {isOpen && modalContent}



          
          {/* <Button onClick={() => {setOpen(!isOpen)}}>Toggle</Button> */}
        
      </div>

      {/* <Hidden smUp>
        <Drawer open={isOpen} anchor="bottom">
          {modalContent}
        </Drawer>
      </Hidden> */}

      {/* <Hidden smDown>
        <Modal
          open={isOpen}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal_container">
            <Fade in={isOpen}>{modalContent}</Fade>
          </div>
        </Modal>
      </Hidden> */}
    </>
  );
}
