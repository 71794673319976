import { Typography } from "@mui/material";
import React from "react";
import FadeIn from "react-fade-in";
import { useLocation } from "react-router-dom";
import GenericPhoneLogin from "../components/authentication/steps/GenericPhoneLogin";


export default function Login() {
  
  const location = useLocation()

  let redirect

  if (location.state?.from) {
    redirect=location.state.from
  } else {
    redirect="/"
  }

  return (
    <div className="maincontent_center">
      <FadeIn>
        <div className="center_text">
          <GenericPhoneLogin redirect={redirect} />
        </div>
      </FadeIn>
    </div>
  );
}
