import React from "react";
import ProfilePicture from "../authentication/ProfilePicture";
import Logo from "../brand/Logo";
import { Plus } from "phosphor-react";
import { Button, Divider, Hidden, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

export default function MainHeader() {
  return (
    <div className="MainHeader_container">
      <div className="MainHeader_inner_container">
        <Logo />
        <div className="MainHeader_icons">
          <ProfilePicture />
          <Hidden smUp>
            <Link to={"/create"} color="error">
              <Plus size={30} weight="bold" className="MainHeader_icon" />
            </Link>
          </Hidden>
          <Hidden smDown>
            <Button
              component={Link}
              to={"/create"}
              variant="outlined"
              size="small"
              sx={{ ml: 2 }}
            >
              Create Event
            </Button>
          </Hidden>
        </div>
      </div>
      <Hidden mdDown>
        <Divider />
      </Hidden>
    </div>
  );
}
