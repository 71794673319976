import React, { useState, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import firebase from "../../../util/firebase";
import "react-phone-input-2/lib/style.css";

//Phone number
import OtpInput from "react-otp-input-rc-17";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { Button, Link, TextField, Typography } from "@mui/material";
import InputMask from "react-input-mask";

export default function PhoneStep(props) {
  const auth = getAuth(firebase);

  //Set State
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [confirm, setConfirm] = useState("");
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState("");
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);

  const dispatch = useDispatch();

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          setRecaptchaPassed(true);
          onSignInSubmit();
        },
      },
      auth
    );
  };

  const passStatus = () => {
    if (props.listener) {
      props.listener(2);
    }
  };

  const onSignInSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!recaptchaPassed) {
      setUpRecaptcha();
    }
    var phoneNumber = "+" + phone;

    var appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setStep(2);
        dispatch({ type: "SET_EMOJI", payload: "point" });
        passStatus();
        setErrors("");
      })
      .catch(function (error) {
        setLoading(false);
        setErrors(`${error}`);
        console.log(error);
      });
  };

  const confirmPhoneNumber = (otp) => {
    setLoading(true);
    var code = otp;
    window.confirmationResult
      .confirm(code)
      .then(function (result) {
        dispatch({ type: "SET_EMOJI", payload: "shaka" });
      })
      .catch(function (error) {
        setLoading(false);
        setErrors("Could not verify code. Please try again");
      });
  };

  const setOTP = (otp) => {
    var str = otp.target.value.replace(/\s/g, '');
    setConfirm(str);
    if (str.length === 6) {
      otp.target.blur()
      confirmPhoneNumber(str);
    }
  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-box">
        <div id="recaptcha-container"></div>
        {step === 1 && (
          <form onSubmit={onSignInSubmit}>
            {!props.subscribeFlow && (
              <div className="stacked_row">
                {loading ? (
                  <>
                  <Typography variant="h4">Checking phones number...</Typography>
                  <input autoFocus type="text" className="trick_keyboard" />
                  </>
                ) : (
                  <>
                  <Typography variant="h3">Howdy!</Typography>
                  <Typography variant="h5" sx={{ pt: 1 }} >To respond to this imvyt confirm your phone number.</Typography>
                  </>
                )}
              </div>
            )}
            {!loading && (
              <div className="stacked_row">
                <PhoneInput
                  country={"us"}
                  id="phonenumber"
                  onChange={setPhone}
                  value={phone}
                  containerClass="phoneInputContainer"
                  inputClass="phoneInputInput"
                  dropdownClass="phoneInputDropdown"
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true
                  }}
                />

                {errors && (
                  <div className="form-error-box">
                    There was an error with your phone number. Please try again.
                  </div>
                )}
              </div>
            )}
            {!loading && (
              <div className="stacked_row">
                <Button size="large" variant={phone.length <=7 ? 'outlined' : 'contained'} disableElevation fullWidth type="submit" disabled={phone.length <=7}>
                  Next
                </Button>
              </div>
            )}
          </form>
        )}
        {step === 2 && (
          <>
            <div className="subscribe-flow-text mb18">
              <p>Enter the code sent to +{phone}.</p>
            </div>
            <div className="mb18">
              {/* <OtpInput
                value={confirm}
                shouldAutoFocus={true}
                isInputNum={true}
                containerStyle="OTPContainer"
                inputStyle="OTPInput"
                numInputs={6}
                onChange={setOTP}
                hasErrored={errors ? true : false}
                errorStyle="OTPError"
              /> */}

              <input autoComplete="one-time-code" autoComplete="one-time-code" className="otp_singleinput_textfield" maxLength={6} type="text" value={confirm} autoFocus={step === 2} onChange={setOTP} />
              <div className="otp_lines"></div>

            </div>
            <div className="mb18 ErrorText">{errors}</div>
          </>
        )}
        {loading && <div>Loading</div>}
      </div>

      <div className="stacked_row">
        <Typography variant="legal">
          This site is protected by reCAPTCHA. <br />The Google
          <Link href="https://policies.google.com/privacy"> Privacy Policy</Link> and
          <Link href="https://policies.google.com/terms"> Terms of Service </Link>
          apply.
        </Typography>
      </div>
    </div>
  );
}
