const initialState = {
  loading: false,
  emoji: "wave",
  drawerOpen: false,
  newEvent: {
    step: 1,
  },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EMOJI":
      return {
        ...state,
        emoji: action.payload,
      };

    case "SET_NEW_EVENT_PRIVACY":
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          privacy: action.payload,
        },
      };

    case "SET_NEW_EVENT_STEP":
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          step: action.payload,
        },
      };

    case "DRAWER_OPEN":
      return {
        ...state,
        drawerOpen: true,
      };

    case "DRAWER_CLOSED":
      return {
        ...state,
        drawerOpen: false,
      };

      case "SET_LOADING":
        return {
          ...state,
          loading: true,
        };
  
      case "STOP_LOADING":
        return {
          ...state,
          loading: false,
        };
  
    // OLD

    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        loading: null,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
        conversations: {
          ...state.conversations,
        },
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        loading: null,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    case "CLEAR_FORM":
      return {
        ...state,
        clearForm: action.payload,
      };
    case "LOADING_ADD_FRIEND":
      return {
        ...state,
        loading: "ADD_FRIEND",
      };
    case "SET_EVENT":
      return {
        ...state,
        event: {
          ...action.payload,
        },
      };
    case "UPDATE_INVITED":
      return {
        ...state,
        event: {
          ...state.event,
          invited: [...state.event.invited, ...action.payload.invited],
        },
      };
    case "SET_MESSAGES":
      return {
        ...state,
        messages: [...action.payload],
      };
    case "SET_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.target]: [...action.payload],
        },
      };
    case "EMPTY_EVENT":
      return {};
    case "SET_EVENTS":
      return {
        ...state,
        events: [...action.payload],
      };
    case "SET_GROUPS":
      return {
        ...state,
        groups: [...action.payload],
      };
    default:
      return state;
  }
};

export default uiReducer;
