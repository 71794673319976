import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Logo() {

    let navigate = useNavigate();

    const goHome = () => {
        navigate('/')
    }
    return (
        
        <svg onClick={goHome} className='logo' width={80} id="imvyt" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.51 150.9">
            <path className="logoFill" d="M271.59,301a23.64,23.64,0,0,0-8.09-11.84q-5.76-4.49-16.21-4.49a30,30,0,0,0-16.72,4.53,29,29,0,0,0-10.37,12h0a23.52,23.52,0,0,0-8-12q-5.73-4.53-16.26-4.53a30,30,0,0,0-16.68,4.53,28.17,28.17,0,0,0-8.24,8.18V286.32H143.5v82.19h27.55V327a35.5,35.5,0,0,1,.77-7.55,19.31,19.31,0,0,1,2.4-6.11,12.27,12.27,0,0,1,4.07-4.07,10.92,10.92,0,0,1,5.76-1.47,10.7,10.7,0,0,1,6,1.47,9,9,0,0,1,3.29,3.95,18.1,18.1,0,0,1,1.39,5.46,58.47,58.47,0,0,1,.31,6.15v43.65h27.4V326.79a33.6,33.6,0,0,1,.78-7.35,20.31,20.31,0,0,1,2.32-6.07,11.59,11.59,0,0,1,4-4.07,11,11,0,0,1,5.77-1.47,11.3,11.3,0,0,1,5.84,1.32,8.14,8.14,0,0,1,3.29,3.67,18.47,18.47,0,0,1,1.47,5.42,51.81,51.81,0,0,1,.39,6.54v43.73h27.63V317.2A53.47,53.47,0,0,0,271.59,301Z" transform="translate(-110.07 -253.45)"/>
            <polygon className="logoFill" points="249.08 32.87 225.01 115.07 187.63 115.07 163.09 32.87 191.58 32.87 206.32 97.97 220.52 32.87 249.08 32.87"/>
            <path className="logoFill" d="M375.63,404.35q-4.17,0-7.35-.27a21.93,21.93,0,0,1-4-.58V383.14a12.35,12.35,0,0,0,3.6.7c1.72.15,3.57.23,5.53.23a14.78,14.78,0,0,0,6.38-1.36,15.27,15.27,0,0,0,5.07-3.79,16.59,16.59,0,0,0,3.33-5.92l1.09-3.17-28.33-83.51h28.79l14.6,59.85,14-59.85h28.32l-27.55,85.29a71.66,71.66,0,0,1-8.47,17.84,31.73,31.73,0,0,1-13.28,11.1Q389.1,404.35,375.63,404.35Z" transform="translate(-110.07 -253.45)"/>
            <path className="logoFill" d="M501,370.37a72.19,72.19,0,0,1-10.53-.81,32.15,32.15,0,0,1-10.22-3.29,19.29,19.29,0,0,1-7.66-7.43q-2.94-4.95-2.94-13.16V307.21H444.93l6.75-20.89h2.18a61.52,61.52,0,0,0,8.52-.47,7.26,7.26,0,0,0,4.72-2.32,11.3,11.3,0,0,0,2-5.77,91.49,91.49,0,0,0,.5-10.87v-7.58h27.71v27h23.22v20.89H497.36v31a24.56,24.56,0,0,0,1.16,8.13c.77,2.16,2.79,3.25,6,3.25a22.59,22.59,0,0,0,5.1-.55,25.18,25.18,0,0,0,3.56-1v20.28a29.76,29.76,0,0,1-4.87,1.35A36.46,36.46,0,0,1,501,370.37Z" transform="translate(-110.07 -253.45)"/>
            <circle className="logoFill" cx="13.66" cy="13.66" r="13.66"/>
            <polygon className="logoFill" points="0.1 32.87 0 32.87 0 115.07 27.32 115.07 27.32 32.87 27.22 32.87 0.1 32.87"/>
        </svg>
        
    )
}
