import React from "react";
import MainHeader from "../components/ux/MainHeader";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import Create from "../pages/Create";
import Login from "../pages/Login";
import EventOverview from "../pages/EventOverview";
import Home from "../pages/Home";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { LinearProgress } from "@mui/material";

export default function AppRoutes() {
  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");

  const ui = useSelector((state) => state.ui);

  function RequireAuth({ children }) {
    const location = useLocation();
    return authenticated ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ from: location }} />
    );
  }

  return (
    <div
      className={`App ${ui.drawerOpen ? "position-fixed" : "normal-position"}`}
    >
      {ui.loading && <div className="AppRoutes_loading"><LinearProgress color="secondary"/></div>}
      <MainHeader />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/create/" element={<Create step={1} />} />
        <Route path="/create/details" element={<RequireAuth><Create step={2} /></RequireAuth>}/>
        <Route path="/create/share" element={<Create step={3} />} />
        <Route path="/login" element={<Login />} />

        <Route path="/:eventId" element={<EventOverview />} />
      </Routes>
    </div>
  );
}
