const sharedTheme = {
  shape: {
    borderRadius: 16,
  },   
  typography: {
        fontFamily: [
          'Poppins',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h3: {
          fontFamily: 'DM Sans',
          fontWeight: 800,
          fontSize: '3.5em',
          lineHeight: 1,
          letterSpacing: '-.03em',
        },
        h4: {
          fontFamily: 'DM Sans',
          fontWeight: 700,
          fontSize: '2em',
          lineHeight: 1.2,
          letterSpacing: '-.01em',
        },
        h5: {
          fontFamily: 'DM Sans',
          lineHeight: 1,
          letterSpacing: '-.01em',
        },
        itemCategory: {
          fontWeight: 500,
          fontSize: '0.7em',
          lineHeight: 1.1,
          letterSpacing: '-.01em',
          textTransform: 'uppercase'

        },
        itemName: {
          fontSize: '1.1em',
          lineHeight: 1.1,
          letterSpacing: '-.01em',
        },
        listTitle: {
          fontSize: '1em',
          lineHeight: '1.5em'
        },
        listSubtitle: {
          fontSize: '0.75em',
          lineHeight: '1.1em'
        },
        legal: {
          fontWeight: 200,
          fontSize: '0.7em',
          letterSpacing: '-.01em',
        },
        clickableListTitle: {
          fontWeight: 500,
          fontSize: '1em',
          lineHeight: '1em'
        }
      },
}

export default sharedTheme