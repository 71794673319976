//Imports
import "./App.css";
import firebase from "./util/firebase";
import history from "./util/history";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";

//Components
import { exportedGetToken, tokenRefresh } from "./redux/actions/userActions";
import ProfilePicture from "./components/authentication/ProfilePicture";
import Home from "./pages/Home";
import EventOverview from "./pages/EventOverview";
import MainHeader from "./components/ux/MainHeader";
import { CssBaseline, useMediaQuery } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

//Import themes
import darkTheme from "./theme/DarkTheme";
import lightTheme from "./theme/LightTheme";

import AppRoutes from './util/AppRoutes'

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          await exportedGetToken();
        } catch(e) {
          return console.log(e)
        }
        console.log('trying again')
        return axios.request(error.config);
      } else {
        console.log("already retried. fail out");
      }
    }
    return Promise.reject(error);
  }
);


// Authentication Check
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch(tokenRefresh());
  }
});

// App
function App() {
  //Theme
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let theme = prefersDarkMode
    ? createTheme(darkTheme)
    : createTheme(lightTheme);
  theme = responsiveFontSizes(theme);

  return (
    
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
