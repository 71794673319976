// USER REDUCER TYPES
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const LOADING_USER = 'LOADING_USER';
export const LOADING_USERS = 'LOADING_USERS';

export const SET_FRIENDS = 'SET_FRIENDS';

export const CLEAR_LOADING_USER = 'CLEAR_LOADING_USER';
export const LOADING_USER_SETTING = 'LOADING_USER_SETTING';
export const SET_FBINIT = 'SET_FBINIT';
export const SET_EXERCISER = 'SET_EXERCISER';
export const SET_SUBSCRIPTION_ENABLEMENT = 'SET_SUBSCRIPTION_ENABLEMENT';



export const OPEN_INSTRUCTOR_DIALOG = 'OPEN_INSTRUCTOR_DIALOG';
export const CLOSE_INSTRUCTOR_DIALOG = 'CLOSE_INSTRUCTOR_DIALOG';


export const CLOSE_SETUP_DIALOG = 'CLOSE_SETUP_DIALOG';

export const CREDENTIALS_FORM_UPDATE = 'CREDENTIALS_FORM_UPDATE';



export const CHANGE_SETUP_PROGRESS = 'CHANGE_SETUP_PROGRESS';
export const SUBSCRIPTION_CHANGE_CONFIRMATION = 'SUBSCRIPTION_CHANGE_CONFIRMATION';

export const SET_INSTRUCTOR_SUBSCRIPTION_DETAILS_BILLING = 'SET_INSTRUCTOR_SUBSCRIPTION_DETAILS_BILLING';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const ATTEMPTING_PAYMENT = 'ATTEMPTING_PAYMENT';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';




//UI REDUCER TYPES
export const SETUP_REQUIRED = 'SETUP_REQUIRED';
export const ACCESS_ISSUE = 'ACCESS_ISSUE';
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const GRANULAR_LOADING_UI = 'GRANULAR_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SUBSCRIBE_ACTION = 'SUBSCRIBE_ACTION';
export const CLOSE_SUBSCRIBE_ACTION = 'CLOSE_SUBSCRIBE_ACTION';
export const LOADING_DATA = 'LOADING_DATA';
export const GRANULAR_LOADING_DATA = 'GRANULAR_LOADING_DATA';
export const CLEAR_GRANULAR_LOADING_DATA = 'CLEAR_GRANULAR_LOADING_DATA';
export const BACKGROUND_LOADING_DATA = 'BACKGROUND_LOADING_DATA';
export const BACKGROUND_LOADING_DATA_COMPLETE = 'BACKGROUND_LOADING_DATA_COMPLETE';
export const UI_PROCEED = 'UI_PROCEED'
export const CONFETTI = 'CONFETTI'
export const CANCEL_CONFETTI = 'CANCEL_CONFETTI'


//DATA REDUCER TYPES
export const SET_WORKOUTS = 'SET_WORKOUTS';
export const SET_FEATURED_WORKOUTS = 'SET_FEATURED_WORKOUTS';
export const SET_FEED = 'SET_FEED';
export const SET_DRAFT_WORKOUTS = 'SET_DRAFT_WORKOUTS';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const DELETE_SCHEDULED = 'DELETE_SCHEDULED';
export const SET_PUBLISHED_WORKOUTS = 'SET_PUBLISHED_WORKOUTS';
export const SET_SCHEDULED_WORKOUTS = 'SET_SCHEDULED_WORKOUTS';
export const SET_WORKOUT = 'SET_WORKOUT';
export const SET_SESSION = 'SET_SESSION';
export const LOG_SESSION_PROGRESS = 'LOG_SESSION_PROGRESS';
export const EDIT_WORKOUT = 'EDIT_WORKOUT';
export const SET_CIRCUIT_IMAGE = 'SET_CIRCUIT_IMAGE';
export const DELETE_CIRCUIT_IMAGE = 'DELETE_CIRCUIT_IMAGE';
export const FAVORITE_WORKOUT = 'FAVORITE_WORKOUT';
export const UNFAVORITE_WORKOUT = 'UNFAVORITE_WORKOUT';
export const SET_FAVORITE_DETAILS = 'SET_FAVORITE_DETAILS';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SET_WORKOUT_DATA = 'SET_WORKOUT_DATA';
export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_DATA_SCHEDULE = 'SET_PROFILE_DATA_SCHEDULE';
export const SET_CURRENT_CIRCUIT = 'SET_CURRENT_CIRCUIT';
export const SET_COMPLETED_CIRCUITS = 'SET_COMPLETED_CIRCUITS';


//STUDIO REDUCER
export const SET_WARMUP_ORDER = 'SET_WARMUP_ORDER';
export const SET_MAIN_ORDER = 'SET_MAIN_ORDER';
export const SET_COOLDOWN_ORDER = 'SET_COOLDOWN_ORDER';
export const SET_GALLERY_ORDER = 'SET_GALLERY_ORDER';
export const EDIT_GALLERY_SLIDE = 'EDIT_GALLERY_SLIDE';


//DISCOVER REDUCER
export const SET_ALL_INSTRUCTORS = 'SET_ALL_INSTRUCTORS';

// SET PICTURES
export const SET_USER_PICTURE = 'SET_USER_PICTURE';
export const SET_WORKOUT_PICTURE = 'SET_WORKOUT_PICTURE';




export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const SET_COVER_PICTURE = 'SET_COVER_PICTURE';


//UI
export const SET_LOGGING_IN = 'SET_LOGGING_IN';
export const UNSET_LOGGING_IN = 'UNSET_LOGGING_IN';
export const OPEN_SUBSCRIBE_DRAWER = 'OPEN_SUBSCRIBE_DRAWER';
export const CLOSE_SUBSCRIBE_DRAWER = 'CLOSE_SUBSCRIBE_DRAWER';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

