// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJPFjeTA8UDYQHUIGGzOD7N8iVFhnay_Q",
  authDomain: "imvyt-prod.firebaseapp.com",
  projectId: "imvyt-prod",
  storageBucket: "imvyt-prod.appspot.com",
  messagingSenderId: "551968335362",
  appId: "1:551968335362:web:d701e48af345068711691b",
  measurementId: "G-3DEN9WGZ00"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);


export const db = getFirestore();
export default firebase