
import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import EventDetails from '../create/EventDetails'
import EventType from '../create/EventType'
import { useSelector } from 'react-redux'
import EventShare from '../create/EventShare'


export default function Create(props) {
    const ui = useSelector((state) => state.ui);
    let navigate = useNavigate();

    let urlStep = props.step
    let formStep = ui.newEvent.step

    // useEffect(() => {
    //     if (urlStep > formStep) {
    //         navigate("/create")
    //     }
    // }, [formStep, urlStep, navigate])

    return (
        <div className="maincontent_center">
            {urlStep === 1 && <EventType />}
            {urlStep === 2 && <EventDetails />}
            {urlStep === 3 && <EventShare />}
        </div>
    )
}
