import sharedTheme from "./SharedTheme";

const darkTheme = {
    ...sharedTheme,
    palette: {
        mode: 'dark',
        background: {
            default: "#0f0f15"
        },
        primary: {
            light: '#E6E8EC',
            main: '#2a57fe',
            dark: '#333333',
        },
        secondary: {
            light: '#3d4154',
            main: '#282b35', // TODO STANDARDIZE? rgba(255, 255, 255, 0.23)
            dark: '#15161b',
        }
      },
      components: {
        // Name of the component
        MuiAccordion: {
          styleOverrides: {
            root: {
              background: 'none',
              border: '1px solid rgba(255, 255, 255, 0.23)',
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
            },
          },
        },
      },
  };
  
  export default darkTheme;